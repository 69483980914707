
import { defineComponent, ref } from 'vue'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import useBasket from '@/composables/useBasket'
import PaymentDetails from '@/components/PaymentDetails.vue'
import useTravelPass from '@/composables/useTravelPass'
import PageHeader from '@/components/PageHeader.vue'

export default defineComponent({
  components: {
    BasketExpiryWarning,
    BasketFooter,
    PaymentDetails,
    PageHeader
  },
  setup () {
    useTravelPass()
    const { basketId } = useBasket()
    const disabled = ref(false)
    const loading = ref(false)
    const paymentDetailRef = ref<InstanceType<typeof PaymentDetails>>()
    const sendPayment = () => {
      paymentDetailRef.value?.sendPayment('TravelPass Payment Confirmation')
    }

    const resetForm = async () => {
      loading.value = false
    }

    return {
      basketId,
      disabled,
      resetForm,
      sendPayment,
      paymentDetailRef,
      loading
    }
  }
})
