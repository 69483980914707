
import {
  defineComponent,
  reactive,
  Ref,
  toRef,
  nextTick,
  ref,
  computed,
  PropType,
  watch,
  onMounted
} from 'vue'
import RadioButton from '@/components/vectors/RadioButton.vue'
import StopSearch from '@/components/StopSearch.vue'
import { BusStop } from '@/models/BusStop'
import { TravelPassDestination } from '@/models/TravelPassDestination'
import dayjs from 'dayjs'
import PassengerSelect from '@/components/PassengerSelect.vue'
import router from '@/router'
import { urlEncodeFares } from '@/helpers/fares'
import { Fares } from '@/models/FareClass'
import { getTravelPassDestinations } from '@/expressway-api/travelPasses.api'
import useTravelPass from '@/composables/useTravelPass'
import {
  addConditions,
  filterStops,
  availableFaresForStop,
  availableTravelPassTypesForStops
} from '@/helpers/travelPasses'
import Spinner from '@/components/Spinner.vue'
import { useStops } from '@/composables/useStops'
import InfoIcon from '@/components/vectors/Info.vue'
import SwapIcon from '@/components/vectors/SwapIcon.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import { TravelPassOption } from '@/models/TravelPass'

interface StopData {
  visible: boolean;
  element: null | typeof StopSearch;
  label: string;
  stop: Ref<BusStop | undefined>;
  stops: Ref<BusStop[] | undefined>;
  error: unknown;
}

const journeyData = {
  oneWay: false,
  promoCode: '',
  departureDate: dayjs().format('YYYY-MM-DD'),
  returnDate: dayjs().format('YYYY-MM-DD'),
  originStop: undefined,
  destinationStop: undefined
}

const loadFares = (fares: Fares, passengers: Fares) => {
  fares.adult = passengers.adult || 0
  fares.child = passengers.child || 0
  fares.student = passengers.student || 0
}

const ADULT = { adult: { caption: '16+ years' } }
const CHILD = { child: { caption: '5 - 15 years' } }
const STUDENT = {
  student: {
    name: 'Young Adult/Student',
    caption: 'With valid with YA / Student Leap Card',
    infoPath: 'passenger_info___student'
  }
}

export default defineComponent({
  components: {
    ModalLink,
    InfoIcon,
    StopSearch,
    PassengerSelect,
    Spinner,
    RadioButton,
    SwapIcon
  },
  props: {
    destinationId: {
      type: Number
    },
    originId: {
      type: Number
    },
    passengers: {
      type: Object as PropType<Fares>
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const { stops } = useStops()
    const travelPassDestinations = ref(Array<TravelPassDestination>())
    const currentOriginStopId = ref(props.originId || 0)
    const fetchingDestinations = ref(false)
    const { destinationCityName, originCityName, travelServiceId, travelPassType } = useTravelPass()
    const loading = ref(false)
    const dataset = reactive(journeyData)
    const fares = reactive({ adult: 0, child: 0, student: 0 } as Fares)
    const travelPassOptions = ref([] as TravelPassOption[])
    if (props.passengers) loadFares(fares, props.passengers)
    const stopState = reactive<{ [index: string]: StopData }>({
      origin: {
        visible: false,
        element: null,
        label: 'Where are you travelling from?',
        stop: toRef(dataset, 'originStop'),
        stops: stops,
        error: null
      },
      destination: {
        visible: false,
        element: null,
        label: 'Where would you like to go?',
        stop: toRef(dataset, 'destinationStop'),
        stops: ref(undefined),
        error: null
      }
    })

    const passengerTypes = computed(() => {
      const passengers = {}
      const availableFares: string[] = availableFaresForStop(
        stopState?.destination?.stop,
        selectedTravelPass.value?.Conditions
      )
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      // eslint-disable-next-line max-len
      availableFares.includes('BONUS_SCHEME_GROUP.ADULT') ? Object.assign(passengers, ADULT) : fares.adult = 0
      // eslint-disable-next-line max-len
      availableFares.includes('BONUS_SCHEME_GROUP.CHILDREN') ? Object.assign(passengers, CHILD) : fares.child = 0
      // eslint-disable-next-line max-len
      availableFares.includes('BONUS_SCHEME_GROUP.STUDENT') ? Object.assign(passengers, STUDENT) : fares.student = 0

      return passengers
    })

    watch(stops, () => {
      stopState.destination.stop = stops.value?.find(stop =>
        stop.BusStopId === props.destinationId)

      stopState.origin.stop = stops.value?.find(stop =>
        stop.BusStopId === props.originId)

      loading.value = false
    })

    const showSearch = (key: string) => {
      stopState[key].visible = true
      nextTick(() => stopState[key].element?.focus())
    }

    // eslint-disable-next-line complexity
    const selectTravelService = (travelPassOption: TravelPassOption) => {
      travelServiceId.value = travelPassOption.travelServiceId
      travelPassType.value = travelPassOption.name
      if (props.passengers && Object.keys(props.passengers).length > 0) {
        loadFares(fares, props.passengers)
      } else {
        if (!('adult' in passengerTypes.value)) {
          fares.adult = 0
        }
        if (!('child' in passengerTypes.value)) {
          fares.child = 0
        }
        if (!('Young Adult / Student' in passengerTypes.value)) {
          fares.student = 0
        }
      }
    }

    const fetchDestinations = async (stopId: number): Promise<void> => {
      travelPassOptions.value = [] as TravelPassOption[]
      travelServiceId.value = ''
      loading.value = true
      fetchingDestinations.value = true
      travelPassDestinations.value = await getTravelPassDestinations(stopId)
      stopState.destination.stops =
        filterStops(stops.value, travelPassDestinations.value, currentOriginStopId.value)

      fetchingDestinations.value = false
      loading.value = false
      const newOptions = [] as TravelPassOption[]
      for (const option of travelPassDestinations.value) {
        newOptions.push({
          description: option.Description,
          name: option.Name,
          travelServiceId: option.TravelServiceId
        })
      }
      travelPassOptions.value = newOptions
      validateSelectedTravelPass()
      if (!stopState.destination.stops?.find(
        stopOption => stopOption.BusStopId === stopState.destination.stop?.BusStopId
      )) {
        stopState.destination.stop = undefined
      }
    }

    const closeJourney = (journey: StopData, journeyName: string) => {
      journey.visible = false

      if (!stopState.origin.stop) return

      currentOriginStopId.value = stopState.origin.stop.BusStopId
      if (journeyName === 'origin') fetchDestinations(currentOriginStopId.value)

      if (journeyName === 'destination') recalculateTravelPassOptions()
    }

    const validateSelectedTravelPass = () => {
      if (travelPassType.value) {
        const selectedTravelPassIsValid = travelPassOptions.value.find(
          option => option.name === travelPassType.value)
        if (!selectedTravelPassIsValid) {
          selectTravelService(travelPassOptions.value[0])
        } else {
          selectTravelService(selectedTravelPassIsValid)
        }
      } else {
        selectTravelService(travelPassOptions.value[0])
      }
    }
    // eslint-disable-next-line complexity
    const recalculateTravelPassOptions = () => {
      if (!stopState.origin.stop || !stopState.destination.stop) {
        return
      }
      const types = availableTravelPassTypesForStops(
        stopState.destination.stop,
        travelPassDestinations.value
      )
      travelPassOptions.value = types
      validateSelectedTravelPass()
    }

    const switchDirection = async () => {
      const newDestination = stopState.origin.stop
      stopState.origin.stop = stopState.destination.stop
      if (!stopState.origin.stop) return

      currentOriginStopId.value = stopState.origin.stop.BusStopId
      await fetchDestinations(stopState.origin.stop.BusStopId)
      if (stopState.destination.stops?.find(
        stopItem => stopItem.BusStopId === newDestination?.BusStopId
      )) {
        stopState.destination.stop = newDestination
      } else {
        stopState.destination.stop = undefined
      }
    }

    const passengerNumber = computed((): number => Object.keys(fares).reduce(
      (passengersNumber: number, key: string): number => passengersNumber + (fares[key] || 0), 0))

    const valid = computed((): boolean =>
      !fetchingDestinations.value &&
      !!stopState.origin.stop &&
      !!stopState.destination.stop &&
      passengerNumber.value > 0
    )

    const selectedTravelPass = computed(() => {
      const selectedTravelPass = travelPassDestinations.value.find(
        travelPassDestination =>
          travelPassDestination.TravelServiceId === travelServiceId.value)
      if (!selectedTravelPass?.Conditions) return
      return selectedTravelPass
    })

    // eslint-disable-next-line complexity
    const confirm = () => {
      if (
        !stopState.origin.stop ||
        !stopState.destination.stop ||
        fetchingDestinations.value ||
        !selectedTravelPass.value
      ) return
      originCityName.value = stopState.origin.stop.CityName
      destinationCityName.value = stopState.destination.stop.CityName

      addConditions(
        selectedTravelPass.value.Conditions,
        stopState.origin.stop,
        stopState.destination.stop, fares
      )

      router.push({ name: 'Select TravelPass Date', query: { fares: urlEncodeFares(fares) } })
    }

    const passengersText = computed((): string =>
      `(${passengerNumber.value} passenger${passengerNumber.value !== 1 ? 's' : ''})`
    )

    onMounted(() => {
      if (stopState.origin.stop?.BusStopId) {
        fetchDestinations(stopState.origin.stop?.BusStopId)
      }
    })

    return {
      switchDirection,
      stops,
      stopState,
      showSearch,
      fares,
      expandPassengers: ref(false),
      passengerTypes,
      valid,
      confirm,
      closeJourney,
      travelPassDestinations,
      passengersText,
      loading,
      travelPassOptions,
      selectTravelService,
      travelServiceId,
      selectedTravelPass
    }
  }
})
