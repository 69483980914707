
import InfoIcon from '@/components/vectors/Info.vue'
import TravelPassIcon from '@/components/vectors/TravelPass.vue'
import ModalLink from '@/components/CMSModalLink.vue'

import {
  defineComponent
} from 'vue'

export default defineComponent({
  components: {
    InfoIcon,
    TravelPassIcon,
    ModalLink
  }
})
