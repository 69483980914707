
import InfoIcon from '@/components/vectors/Info.vue'
import TravelPass from '@/components/vectors/TravelPass.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import Input from '@/components/Input.vue'
import useVuelidate from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { getTravelPass } from '@/expressway-api/travelPasses.api'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import router from '@/router'

import {
  defineComponent,
  ref,
  computed
} from 'vue'

export default defineComponent({
  components: {
    InfoIcon,
    TravelPass,
    ModalLink,
    GenericError,
    Modal,
    'v-input': Input
  },
  props: {
  },
  setup () {
    const rules = {
      travelPassCode: {
        required,
        minLength: minLength(12)
      }
    }
    const travelPass = ref({
      travelPassCode: ''
    })
    const validator = useVuelidate(rules, travelPass)
    const disabled = computed(() => validator.value.$invalid)
    const error = ref('')

    const confirm = async () => {
      getTravelPass(travelPass.value.travelPassCode)
        .then(() => {
          router.push({
            name: 'TravelPass Journey Search',
            query: { travelPass: travelPass.value.travelPassCode }
          })
        })
        .catch(e => { error.value = e.message })
    }

    return {
      disabled,
      validator,
      confirm,
      error
    }
  }
})
