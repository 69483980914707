
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  watch
} from 'vue'
import Checkbox from '@/components/Checkbox.vue'
import TicketTypeQuantitySelect from '@/components/TicketTypeQuantitySelect.vue'

export default defineComponent({
  components: { Checkbox, TicketTypeQuantitySelect },
  emits: ['update:modelValue', 'update:expanded'],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    passengerTypes: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props, { emit }) {
    const fares = reactive(props.modelValue)
    const totalPassengers = computed(() =>
      Object.values(fares).reduce((sum, addend) => sum + addend, 0)
    )

    watch(fares, () => { emit('update:modelValue', fares) })

    const onClickOutside = ({ target }: Event) => {
      if (
        target instanceof Element &&
        target.closest('.journey-passenger-select')
      ) return

      emit('update:expanded', false)
    }

    onMounted(() => document.addEventListener('click', onClickOutside))
    onUnmounted(() => document.removeEventListener('click', onClickOutside))

    return {
      totalPassengers,
      fares
    }
  }
})
