
import { defineComponent } from 'vue'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'

export default defineComponent({
  props: {
    originCityName: {
      type: String,
      required: true
    },
    destinationCityName: {
      type: String,
      required: true
    },
    travelPassType: {
      type: String,
      required: true
    },
    passengersType: {
      type: String,
      required: true
    },
    passengersNumber: {
      type: Number,
      required: true
    }
  },
  components: {
    PeopleIcon
  }
})
