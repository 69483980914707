<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 6V2C20 0.9 19.1 0 18 0H2C0.9 0 0.01 0.9 0.01 2V6C1.11 6 2 6.9 2 8C2 9.1 1.11 10 0 10V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V10C18.9 10 18 9.1 18 8C18 6.9 18.9 6 20 6ZM18 4.54C16.81 5.23 16 6.53 16 8C16 9.47 16.81 10.77 18 11.46V14H2V11.46C3.19 10.77 4 9.47 4 8C4 6.52 3.2 5.23 2.01 4.54L2 2H18V4.54ZM10 10.12L7.07 12L7.95 8.63L5.26 6.43L8.73 6.22L10 3L11.26 6.23L14.73 6.44L12.04 8.64L12.93 12L10 10.12Z"
    />
  </svg>
</template>
