
import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted
} from 'vue'
import { Fares, passengersNumber } from '@/helpers/fares'
import Checkbox from '@/components/Checkbox.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import dayjs from 'dayjs'
import Modal from '@/components/Modal.vue'
import useBasket from '@/composables/useBasket'
import useTravelPass from '@/composables/useTravelPass'
import { TravelPassPayload } from '@/models/TravelPass'
import Spinner from '@/components/Spinner.vue'
import { FareMap } from '@/models/FareClass'

interface Passenger {
  name: string;
  index: number;
  label: string;
  date: string | null;
}

const formatDatePlaceholder = (date: string) =>
  date ? dayjs(date).format('DD/MM/YYYY') : 'DD/MM/YYYY'

export default defineComponent({
  props: {
    originId: {
      type: Number,
      required: true
    },
    destinationId: {
      type: Number,
      required: true
    },
    fares: {
      type: Object as PropType<Fares>,
      required: true
    }
  },
  components: {
    Checkbox,
    DatePicker,
    Modal,
    Spinner
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const sameActivationDate = ref(true)
    const passengers = ref(Array<Passenger>())
    const selectedPassenger = ref<Passenger | null>(null)
    const showModal = ref(false)
    const today = dayjs().format('YYYY-MM-DD')
    const maxDate = dayjs().add(180, 'day').format('YYYY-MM-DD')
    const sharedPassenger = ref<Passenger>({ name: '', index: 0, label: '', date: null })
    const { conditions, travelServiceId } = useTravelPass()
    const { confirmTravelPassBasket } = useBasket()
    const loading = ref(false)
    const multipleTravelPasses = computed((): boolean =>
      passengersNumber(props.fares) > 1
    )

    const preparePassengers = (): void => {
      for (const fare in props.fares) {
        for (let i = 0; i < (props.fares[fare] || 0); i++) {
          passengers.value.push({
            name: fare,
            index: i + 1,
            label: fare,
            date: null
          })
        }
      }
    }

    const openModal = (passenger: Passenger): void => {
      showModal.value = true
      selectedPassenger.value = passenger
    }

    const closeModal = (): void => {
      showModal.value = false
      selectedPassenger.value = null
    }

    const valid = computed((): boolean => {
      const todayDate = new Date(today)

      if (sameActivationDate.value) {
        return !!sharedPassenger.value.date && new Date(sharedPassenger.value.date) >= todayDate
      }

      return passengers.value.every(passenger =>
        passenger.date && new Date(passenger.date) >= todayDate
      )
    })

    onMounted(preparePassengers)

    const conditionForPassenger = (passenger: Passenger) => {
      const condition = conditions.value.find(condition =>
        condition.passengerTypeId === FareMap[passenger.label.toLowerCase()]
      )
      condition?.activationDates.push(passenger.date || '')

      return condition
    }

    const generateTravelPass = (passenger: Passenger): TravelPassPayload => {
      const condition = conditionForPassenger(passenger)
      const payload: TravelPassPayload = {
        TravelServiceId: travelServiceId.value,
        ConditionId: condition?.conditionId,
        ZonePairPriceId: condition?.zonePairPriceId,
        CustomerDefinedActivationTime: dayjs(passenger.date || '').format('YYYY-MM-DD')
      }

      return payload
    }

    const confirm = () => {
      loading.value = true
      const travelPasses: TravelPassPayload[] = []
      passengers.value.forEach(passenger => {
        if (sameActivationDate.value) passenger.date = sharedPassenger.value.date
        travelPasses.push(generateTravelPass(passenger))
      })
      confirmTravelPassBasket(travelPasses).finally(() => {
        loading.value = false
      })
    }

    return {
      conditions,
      loading,
      multipleTravelPasses,
      sameActivationDate,
      passengers,
      dayjs,
      showModal,
      openModal,
      closeModal,
      selectedPassenger,
      today,
      valid,
      sharedPassenger,
      maxDate,
      formatDatePlaceholder,
      confirm
    }
  }
})
