
import { defineComponent, ref } from 'vue'
import BasketFooter from '@/components/BasketFooter.vue'
import BuyerDetails from '@/components/BuyerDetails.vue'
import { buyerDetail } from '@/expressway-api/buyDetails.api'
import useSnackbar from '@/composables/useSnackbar'
import useBasket from '@/composables/useBasket'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import router from '@/router'
import { buyerState } from '@/composables/useBuyerDetails'
import useTravelPass from '@/composables/useTravelPass'

export default defineComponent({
  components: {
    BasketFooter,
    BasketExpiryWarning,
    BuyerDetails
  },
  setup () {
    useTravelPass()
    const { setSnackbar } = useSnackbar()
    const onBasketError = (error: Error) => setSnackbar(error.message)
    const { basketId } = useBasket(onBasketError)
    if (!basketId.value) throw Error('BasketId not found')
    const disabled = ref(true)

    const buyerDetailsCall = async () => {
      try {
        await buyerDetail(basketId.value, {
          FirstName: buyerState.firstName,
          LastName: buyerState.lastName,
          Email: buyerState.email,
          IsSendingInfoAllowed: buyerState.marketingConsent
        })

        router.push({ name: 'TravelPass Payment Details' })
      } catch (e) {
        setSnackbar(e.message)
      }
    }

    return {
      buyerDetailsCall,
      disabled
    }
  }
})
